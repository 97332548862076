<template>
  <!-- web端 -->
  <!-- 登录界面 -->
  <!-- HTML -->
  <!-- CSS -->
  <!-- JS -->
  <div class="Log">
    <div
      ref="wavesRef"
      style="width: 100%; height: 100vh; position: fixed"
    ></div>
    <div class="container active" id="container">
      <div class="form-container sign-in" v-if="!signup">
        <h1 style="font-family: 'ShuHei'">关于我们</h1>
        <div class="about_us flex">
          我们是一群由兴趣驱动的开发者，怀揣着对技术的无限热爱与追求，走到了一起。在这个充满挑战与机遇的数字时代，我们不仅仅是为了工作而聚在一起，更是为了共同的兴趣和梦想——用代码编织未来，创造更加便捷、有趣且富有创意的数字产品。
          从最初的网页设计，到后来的小程序开发，我们始终保持着对技术的敏锐洞察力和持续学习的热情。我们相信，每一个网页、每一个小程序，都是连接用户与世界的桥梁，是展现创意与智慧的舞台。因此，我们始终将用户体验放在首位，用心去感受用户的需求，用技术去满足他们的期待。
        </div>
        <div class="signin_footer" @click="sel_signUp()">
          <div
            style="
              display: inline-block;
              width: 10px;
              height: 10px;
              border-radius: 10px;
              background-color: #4280bd;
              margin-right: 10px;
            "
          ></div>
          我要登录
        </div>
      </div>
      <div class="form-container sign-up">
        <form>
          <div class="sign-title flex">庆快校园</div>
          <input type="text" placeholder="账号" v-model="loginForm.username" />
          <div style="color: red" v-if="usernamedis">请输入账号</div>
          <div style="color: red; height: 1.1rem" v-if="!usernamedis"></div>
          <input
            type="password"
            placeholder="密码"
            v-model="loginForm.password"
          />
          <div style="color: red" v-if="passworddis">请输入密码</div>
          <div style="color: red; height: 1.1rem" v-if="!passworddis"></div>
          <button
            style="width: 100%; border-radius: 30px"
            class="sign-btn"
            @click.prevent="Login()"
          >
            登录
          </button>
        </form>
      </div>

      <div class="toggle-container">
        <div class="toggle">
          <div class="toggle-panel toggle-left" ref="BIRDSRef">
            <div class="logo">
              <img
                style="width: 100px; height: 100px; border-radius: 100px"
                src="../../assets//庆快校园1.jpg"
                alt=""
              />
            </div>
            <div class="introduction">
              庆快校园是一款基于前后端分离技术栈开发的综合性工具，通过管理庆快小程序端与后台管理系统相结合，实现便捷小程序运营管理的无缝对接，它不仅简化了点餐流程，还提升了广州大学商业中心饮食店铺的运营效率和顾客满意度。
            </div>

            <div>庆快校园备忘录</div>
            <div class="beian flrow">
              <div style="margin-right: 10px">版权所有：庆快校园项目组</div>
              <div>
                <a
                  href="https://beian.miit.gov.cn/#/Integrated/index"
                  target="_blank"
                  style="
                    font-size: 0.8rem;
                    color: #2143b4;
                    text-decoration: underline;
                  "
                >
                  粤ICP备2024270005号
                </a>
              </div>
            </div>
            <div class="about flrow" @click="sel_signIn()">
              关于我们 &#9733;
            </div>
          </div>
          <div class="toggle-panel toggle-right"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import * as THREE from "three";
import WAVES from "vanta/src/vanta.waves";
import CLOUDS from "vanta/src/vanta.clouds";
import FOG from "vanta/src/vanta.fog";
import BIRDS from "vanta/src/vanta.birds";
export default Vue.extend({
  components: {},
  data() {
    return {
      signup: true,
      usernamedis: false,
      passworddis: false,
      loginForm: {
        username: "",
        password: "",
      },
      loginRules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.wavesEffect = WAVES({
      el: this.$refs.wavesRef,
      THREE: THREE,
    });
    VANTA.WAVES({
      el: this.$refs.wavesRef,
      /*以下为样式配置*/
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      scale: 1.0,
      scaleMobile: 1.0,
      color: 0x2e6396,
      shininess: 76.0,
      waveHeight: 12.0,
      waveSpeed: 0.95,
      zoom: 0.89,
    });
    // this.vantaEffect = FOG({
    //   el: this.$refs.vantaRef,
    //   THREE: THREE,
    // });
    // VANTA.FOG({
    //   el: this.$refs.vantaRef,
    //   /*以下为样式配置*/
    //   mouseControls: true,
    //   touchControls: true,
    //   gyroControls: false,
    //   minHeight: 200.0,
    //   minWidth: 200.0,
    //   highlightColor: 0x0d469b,
    //   midtoneColor: 0x19c4d1,
    //   lowlightColor: 0x05ffde,
    //   baseColor: 0xffffff,
    //   blurFactor: 0.52,
    //   speed: 1.2,
    //   zoom: 0.3,
    // });
    // this.BIRDSEffect = BIRDS({
    //   el: this.$refs.BIRDSRef,
    //   THREE: THREE,
    // });
    // // 修改颜色时 cells 需要全大写字母 可生效
    // VANTA.BIRDS({
    //   el: this.$refs.BIRDSRef,
    //   mouseControls: true,
    //   touchControls: true,
    //   gyroControls: false,
    //   minHeight: 200.0,
    //   minWidth: 200.0,
    //   scale: 1.0,
    //   color1: 14381274,
    //   color2: 16443110,
    // });
  },
  beforeDestroy() {
    if (this.wavesEffect) {
      this.wavesEffect.destroy();
    }
    // if (this.vantaEffect) {
    //   this.vantaEffect.destroy();
    // }
    // if (this.BIRDSEffect) {
    //   this.BIRDSEffect.destroy();
    // }
  },
  methods: {
    handleKeyDown(event) {
      // 判断按键是否为 Enter
      if (event.key === "Enter") {
        this.Login();
      }
    },
    sel_signIn() {
      setTimeout(() => {
        this.signup = false;
      }, 200);
      let container = document.getElementById("container");
      container.classList.remove("active");
    },
    sel_signUp() {
      setTimeout(() => {
        this.signup = true;
      }, 200);
      let container = document.getElementById("container");
      container.classList.add("active");
    },
    Login() {
      console.log(this.loginForm);
      // if (this.loginForm.username == "") {
      //   this.usernamedis = true;
      //   setTimeout(() => {
      //     this.usernamedis = false;
      //   }, 1000);
      //   console.log("请输入账号");
      //   return;
      // }
      // if (this.loginForm.password == "") {
      //   this.passworddis = true;
      //   setTimeout(() => {
      //     this.passworddis = false;
      //   }, 1000);
      //   console.log("请输入账号");
      //   return;
      // }

      axios
        .post("/api/admin/employee/login", this.loginForm)
        .then((res) => {
          if (res.data.code == 1) {
            localStorage.setItem("token", res.data.data.token);
            localStorage.setItem("id", res.data.data.id);
            localStorage.setItem("userName", res.data.data.userName);
            localStorage.setItem(
              "classification",
              res.data.data.classification
            );

            console.log("登录成功");
            localStorage.setItem("menuactive", "/HomePage/PageRight");
            this.$router.replace("/HomePage/PageRight");
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
          this.$message.error("连接超时，请检查网络连接");
        });
    },
  },
});
</script>

<style scoped>
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flrow {
  display: flex;
  flex-direction: row;
}
.Log {
  background-image: url("@/assets/login.jpg");
  background-size: cover; /* 背景图片覆盖整个容器 */
  background-position: center; /* 背景图片居中 */
  background-repeat: no-repeat; /* 背景图片不重复 */
  background-color: #ffffff;
  text-align: center; /* 水平居中对齐 */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

body {
  background-color: #c9d6ff;
  background: linear-gradient(to right, #e2e2e2, #c9d6ff);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.container {
  background-color: rgba(255, 255, 255, 0.6);

  border-radius: 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;
}

.container p {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  margin: 20px 0;
}

.container span {
  font-size: 12px;
}

.container a {
  color: #333;
  font-size: 13px;
  text-decoration: none;
  margin: 15px 0 10px;
}

.container button {
  /* background: linear-gradient(90deg, rgb(5, 164, 212), rgb(1, 90, 255)); */
  /* color: #fff; */
  /* font-size: 12px; */
  padding: 7px 45px;
  border: 1px solid transparent;
  border-radius: 6px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-top: 10px;
  cursor: pointer;
}

.container form {
  /* background-color: #ffffff; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  height: 100%;
}

.container input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #8f8d8d;
  margin: 8px 0;
  padding: 15px 20px 15px 20px;
  font-size: 15px;
  width: 100%;
  outline: none;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in {
  left: 0;
  width: 50%;
  z-index: 2;
  padding: 25px 10px 10px 10px;
}
.about_us {
  padding: 0 30px 0 30px;
  width: 100%;
  height: 350px;
  color: #042225;
  font-size: 1.2rem;
  font-family: "ShuHei";
}
.container.active .sign-in {
  transform: translateX(100%);
}
.signin_footer {
  display: flex;
  text-align: left;
  align-items: center;
  width: 200px;
  height: 50px;
  padding-left: 20px;
  background-image: url("../../assets/bg1.jpg");
  background-size: cover;
  position: absolute;
  right: -100px;
  top: 0px;
  color: #4280bd;
  font-size: 1.3rem;
  font-weight: 900;
  border-radius: 50px;
}
.signup_footer {
  display: flex;
  text-align: left;
  align-items: center;
  width: 200px;
  height: 50px;
  padding-left: 100px;
  background-image: url("../../assets/bg2.jpg");
  background-size: cover;
  position: absolute;
  left: -100px;
  bottom: 0px;
  color: #444444;
  font-size: 1.3rem;
  font-weight: 900;
  border-radius: 50px;
}
.sign-up {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}
.sign-title {
  width: 100%;
  height: 100px;
  color: #5077a4;
  font-size: 5rem;
  font-family: "Daoli";
}
.sign-btn {
  width: 100%;
  border-radius: 30px;
  margin-top: 70px;
  color: #09aaea;
  font-size: 1.6rem;
  background-color: rgba(255, 255, 255, 0.2);
}
.sign-btn:hover {
  color: #ffffff;
  background-color: rgba(0, 153, 255, 0.7);
  box-shadow: 0 0 7px rgba(177, 177, 177, 0.8);
}
.container.active .sign-up {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: move 0.6s;
}

@keyframes move {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }
  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.social-icons {
  margin: 20px 0;
}

.social-icons a {
  border: 1px solid #ccc;
  border-radius: 20%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 3px;
  width: 40px;
  height: 40px;
}

.toggle-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: all 0.6s ease-in-out;
  border-radius: 80px 0 0 80px;
  z-index: 1000;
}

.container.active .toggle-container {
  transform: translateX(-100%);
  border-radius: 0 80px 80px 0;
}

.toggle {
  height: 100%;
  background: linear-gradient(to right, #15a4b1, #27d5e4);
  background-image: url("../../assets/bg1.jpg");
  background-size: cover;
  color: #fff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: all 0.6s ease-in-out;
}

.container.active .toggle {
  transform: translateX(50%);
}

.toggle-panel {
  position: absolute;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 30px;
  text-align: center;
  top: 0;
  transform: translateX(0);
  transition: all 0.6s ease-in-out;
  background-size: 100% 100%;
}

.toggle-left {
  transform: translateX(-200%);
  background-image: url("../../assets/bg1.jpg");
  background-size: cover;
}
.introduction {
  padding: 40px 20px 0px 20px;
  width: 80%;
  height: 200px;
  font-size: 1.2rem;
  line-height: 0cqiu 1.2rem;
  overflow: hidden;
  margin-bottom: 30px;
  font-family: "ShuHei";
}
.about {
  padding: 10px 0 10px 0;
  text-decoration: underline;
  color: #213139;
  font-size: 1.1rem;
  font-weight: 800;
  font-family: "ShuHei";
}
.about:hover {
  color: #4007a2;
}
.beian {
  padding: 10px 0 10px 0;
  font-weight: bold;
  font-size: 0.8rem;
}
.beian a:hover {
  color: #4007a2;
}
.toggle-right {
  right: 0;
  transform: translateX(0);
  background-image: url("../../assets/bg1.jpg");
  background-size: cover;
}

.container.active .toggle-left {
  transform: translateX(0);
}

.container.active .toggle-right {
  transform: translateX(200%);
}
input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
}
input {
  -webkit-text-fill-color: #727272;
}
</style>
